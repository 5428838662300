<template>
  <div class="main">
    <el-row>
      <el-col :span="24">
        <el-row :gutter="40">
          <el-col :span="16">
            <div class="content kefan">
              <!-- 确认所在园区  -->
              <div class="range" id="glhtrk">
                <h4 class="title">管理后台入口</h4>
                <el-divider></el-divider>
                <p class="enterance">
                  <ul>
                    <li>1.智慧湾：https://admin.kefan.com/</li>
                    <li>2.智慧坊：https://zhf.wis-p.cn/</li>
                    <li>3.交运智慧湾：https://jyzhw.jy-wisdombay.cn/</li>
                    <li>4.智慧七立方：https://wis777.kefan.com/</li>
                    <li>5.科房旗下园区：https://kf.kefan.com/</li>
                  </ul>
                </p>
              </div>
              <!-- 账单金额修改 -->
              <div class="range" id="zdjexg">
                <h4 class="title">账单金额修改</h4>
                <el-divider></el-divider>
                <p class="describe">
                  1.若某期账单只需修改账单金额可在账单管理页面中直接编辑账单修改（如图2.13、2.14）
                </p>
                <el-image 
                style="width:100%;"
                :src="img.aurl[0]"
                :preview-src-list="img.aurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.13</p>
                <el-image 
                style="width:100%;"
                :src="img.burl[0]"
                :preview-src-list="img.burl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.14</p>
              </div>

              <!-- 账单收款登记 -->
              <div class="range" id="zdskdj">
                <h4 class="title">账单收款登记</h4>
                <el-divider></el-divider>
                 <p class="describe">
                    1.进入账单页面紫色方框“收款及发票登记跟踪”（如图2.15）
                 </p>
                <el-image 
                style="width:100%;"
                :src="img.curl[0]"
                :preview-src-list="img.curl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.15</p>

                <p class="describe">
                    2.点击目标账单右侧按钮“收款登记”（如图2.16）
                 </p>
                <el-image 
                style="width:100%;"
                :src="img.durl[0]"
                :preview-src-list="img.durl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.16</p>

                <p class="describe">
                    3.跳出收款登记方框（如图2.17），填入付款日期，付款金额，点击完成收款即可使该账单变为“已支付”状态
                 </p>
                <el-image 
                style="width:100%;"
                :src="img.eurl[0]"
                :preview-src-list="img.eurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.17</p>

                <p class="describe">
                    4.也可多次添加登记多笔付款金额（如图2.18）红色方框画出的按钮可对该笔登记进行编辑和删除
                 </p>
                <el-image 
                style="width:100%;"
                :src="img.furl[0]"
                :preview-src-list="img.furl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.18</p>

                <p class="describe">
                    5.若需同时给多份账单进行收款登记可勾选多份账单后点击上方批量收款登记（如图2.19）
                 </p>
                <el-image 
                style="width:100%;"
                :src="img.gurl[0]"
                :preview-src-list="img.gurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.19</p>
                
                <p class="describe">
                    6.出现批量登记确认界面（如图2.20）填写收款日期完成账单批量收款登记
                 </p>
                <el-image 
                style="width:100%;"
                :src="img.hurl[0]"
                :preview-src-list="img.hurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.20</p>
              </div>
              <!-- 账单发票登记 -->
              <div class="range" id="zdfpdj">
                <h4 class="title">账单发票登记</h4>
                <el-divider></el-divider>
                    <p class="describe">
                     1. 在进入账单页面紫色方框“收款及发票登记跟踪”后，点击目标账单右侧按钮“发票登记”（如图2.21）
                    </p>
                    <el-image 
                style="width:100%"
                :src="img.iurl[0]"
                :preview-src-list="img.iurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.21</p>
                <p class="describe">
                  2. 跳出发票登记方框如图（2.22），填入发票号后点击保存按钮即可完成发票登记，发票填写栏未做数值录入限制，可用“、”、“-”来录入多张发票号
                </p>
                <el-image 
                style="width:100%"
                :src="img.jurl[0]"
                :preview-src-list="img.jurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.22</p>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col kefan">
              <el-timeline>
                  <ul>
                    
                    <li v-for="(activity, index) in lists" :key="index" >
                      <el-timeline-item
                      :icon="activity.icon"
                      :type="activity.type"
                      :color="activity.color"
                      :size="activity.size"
                      :timestamp="activity.timestamp">
                      <a :href="activity.target" @click="tolink(index)" :class="link==index?'islink':''">{{activity.name}}</a>
                    </el-timeline-item>
                    </li>
                    <li><el-progress :text-inside="true" :stroke-width="20" :percentage="percentage" color="#4fc08d"></el-progress></li>
                  </ul>
                  
                </el-timeline>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      percentage: 0,
      lists: [
        {
          name: "管理后台入口",
          target: "#glhtrk",
        },
        {
          name: "账单金额修改",
          target: "#zdjexg",
        },
        {
          name: "账单收款登记",
          target: "#zdskdj",
        },
        {
          name: "账单发票登记",
          target: "#zdfpdj",
        },
      ],
      link: 0,
      img: {
        aurl: [this.$img + "cwzdbj/2.13.png"],
        burl: [this.$img + "cwzdbj/2.14.png"],
        curl: [this.$img + "cwzdbj/2.15.png"],
        durl: [this.$img + "cwzdbj/2.16.png"],
        eurl: [this.$img + "cwzdbj/2.17.png"],
        furl: [this.$img + "cwzdbj/2.18.png"],
        gurl: [this.$img + "cwzdbj/2.19.png"],
        hurl: [this.$img + "cwzdbj/2.20.png"],
        iurl: [this.$img + "cwzdbj/2.21.png"],
        jurl: [this.$img + "cwzdbj/2.22.png"],
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.getscroll);
  },
  methods: {
    tolink(index) {
      this.link = index;
    },
    // 获取滚动百分比
    getscroll() {
      // 页面总高
      var totalH = document.documentElement.scrollHeight;
      // 可视高
      var clientH = document.documentElement.clientHeight;
      // 计算有效高
      var validH = totalH - clientH;

      // 滚动条卷去高度
      var scrollH = document.documentElement.scrollTop;

      // 百分比
      var result = Math.round(((scrollH / validH) * 10000) / 100, 2);
      this.percentage = result;
      // console.log("totalH:" + totalH);
      // console.log("clientH:" + clientH);
      // console.log("scrollH:" + scrollH);
      if (result < 7) {
        this.tolink(0);
      } else if (result >= 7 && result < 31) {
        this.tolink(1);
      } else if (result >= 31 && result < 92) {
        this.tolink(2);
      }  else {
        this.tolink(3);
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.getscroll);
  },
};
</script>

<style scoped>
.describe {
  font-size: 14px;
  color: #5e6d82;
}
.el-divider--horizontal {
  width: 80px;
  margin: 10px 0 15px 0;
  height: 3px;
  background-color: #4fc08d;
  box-shadow: 0px 3px 7px #4fc08d;
}
.range {
  margin: 0 0 10% 0;
}
.content p {
  width: 95%;
  word-break: break-all;
  word-wrap: break-word;
  padding: 10px 0;
}
.content {
  padding: 20px 40px;
}
:target {
  padding-top: 80px;
  margin-top: -80px;
}
.col {
  position: fixed;
  top: 80px;
  width: 300px;
  /* height: 30%; */
}
.col a {
  color: #5e6d82;
}
.col a:hover {
  color: #4fc08d;
}
.col a:after {
  color: #4fc08d;
  font-weight: 700;
}
.col .islink {
  color: #4fc08d;
  font-weight: 700;
}
.enterance {
  background: #5e6d82;
  color: aliceblue;
  border-radius: 8px;
  font-size: 14px;
  /* color: #5e6d82; */
}
.enterance li {
  padding: 5px 10px;
}
.describe::first-letter {
  font-size: 20px;
  font-weight: 700;
  text-shadow: 3px 2px 2px #4fc08d;
}
</style>